import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "bi bi-cart fs-2"
}
const _hoisted_3 = {
  key: 1,
  class: "bi bi-receipt fs-2"
}
const _hoisted_4 = {
  key: 2,
  class: "bi bi-journal-check fs-2"
}
const _hoisted_5 = {
  key: 3,
  class: "bi bi-send-check fs-2"
}
const _hoisted_6 = {
  key: 4,
  class: "bi bi-cart-check fs-2"
}
const _hoisted_7 = {
  key: 5,
  class: "bi bi-truck fs-2"
}
const _hoisted_8 = {
  key: 6,
  class: "bi bi-shield-check fs-2"
}
const _hoisted_9 = {
  key: 7,
  class: "bi bi-inboxes fs-2"
}
const _hoisted_10 = {
  key: 8,
  class: "bi bi-box-arrow-right fs-2"
}
const _hoisted_11 = {
  key: 9,
  class: "bi bi-box-arrow-in-right fs-2"
}
const _hoisted_12 = {
  key: 10,
  class: "bi bi-box fs-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "top",
    width: "auto",
    trigger: "hover"
  }, {
    reference: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.value == 'New')
          ? (_openBlock(), _createElementBlock("i", _hoisted_2))
          : (_ctx.value == 'Order Received')
            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
            : (_ctx.value == 'Order Placed')
              ? (_openBlock(), _createElementBlock("i", _hoisted_4))
              : (_ctx.value == 'Estimated Delivered')
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : (_ctx.value == 'Inventory Confirmation')
                  ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                  : (
            _ctx.value == 'EU Shipment' ||
            _ctx.value == 'Overseas Shipment' ||
            _ctx.value == 'CN Shipment'
          )
                    ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                    : (_ctx.value == 'Transit & QC warehouse')
                      ? (_openBlock(), _createElementBlock("i", _hoisted_8))
                      : (
            _ctx.value == 'Transit Warehouse' ||
            _ctx.value == 'Bonded Warehouse' ||
            _ctx.value == 'Local Warehouse'
          )
                        ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                        : (_ctx.value == 'Export Custom Declaration')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                          : (_ctx.value == 'Import Custom Declaration')
                            ? (_openBlock(), _createElementBlock("i", _hoisted_11))
                            : (_ctx.value == 'Domestic Delivery')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_12))
                              : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, _toDisplayString(_ctx.node), 1)
    ]),
    _: 1
  }))
}